// Data.jsx

// import Work1 from "../../assets/work1.jpg";
// import Work2 from "../../assets/work2.jpg";
// import Work3 from "../../assets/work3.jpg";
// import Work4 from "../../assets/work4.jpg";
// import Work5 from "../../assets/work5.jpg";
import tarar from "../../assets/tarar.png";
import Booksy from "../../assets/booksy.png";
import DostApp from "../../assets/DostApp.png";
import chatbot from "../../assets/chatbot.jpg";
import IDP from "../../assets/IDP.jpg";

// export const projectsData = [
//   {
//     id: 1,
//     image: Work1,
//     title: "Web design",
//     category: "web",
//   },
//   {
//     id: 2,
//     image: Work2,
//     title: "App movil",
//     category: "app",
//   },
//   {
//     id: 3,
//     image: Work3,
//     title: "Brand design",
//     category: "design",
//   },
//   {
//     id: 4,
//     image: Work4,
//     title: "App movil",
//     category: "app",
//   },
//   {
//     id: 5,
//     image: Work5,
//     title: "Web design",
//     category: "web",
//   },
// ];

// // projects
// export const projectsNav = [
//   {
//     name: "all",
//   },
//   {
//     name: "web",
//   },
//   {
//     name: "app",
//   },
//   {
//     name: "design",
//   },
// ];


export const projectsData = [
  {
    id: 1,
    image: chatbot,
    title: "AI-powered Chatbot",
    category: "web",
    description: "Developed a modern corporate website for a leading tech company.",
    link: "https://example.com/project1"
  },
  {
    id: 2,
    image: Booksy,
    title: "E-Commerce Book Store",
    category: "web",
    description: "E-commerce book store, featuring user authentication, cart management, and payment processing.",
    link: "https://github.com/Fawaztarar/booksy-FT"
  },
  {
    id: 3,
    image: tarar,
    title: "Ios App Tarar To Do",
    category: "app",
    link: "https://apps.apple.com/gb/app/tarar-to-do/id6499149301"
  },
  {
    id: 4,
    image: IDP,
    title: "Intelligent Document Processing",
    category: "app",
    description: "Built an AI-powered platform for data analytics and business insights.",
    link: "https://example.com/project4"
  },
  {
    id: 5,
    image: DostApp,
    title: "Ios App دوست",
    category: "web",
    description: "iOS Dost App in Urdu Language to share messages, images, videos and voice inpsired by WhatsApp",
    link: "https://example.com/project5"
  },
];

// projects navigation categories
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "design",
  },
];
